<template>
  <div>
    <h1>{{ $t("movie_list_overview") }}</h1>
    <div class="row">
      <div class="card-container col-lg-4">
        <div class="card">
          <div class="card-body row">
            <img
              src="https://i.etsystatic.com/18242346/r/il/7ad080/4580913585/il_570xN.4580913585_3yhu.jpg"
              class="poster-img col-6"
            />
            <img
              src="https://www.vintagemovieposters.co.uk/wp-content/uploads/2023/03/IMG_1887-scaled.jpeg"
              class="poster-img col-6"
            />
            <img
              src="https://s.yimg.com/ny/api/res/1.2/0ZXuB.N3qPpSGyFcuEXdcA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTEwMzU7aD0xNTAw/https://media.zenfs.com/en/homerun/feed_manager_auto_publish_494/d05a3f087fa57f6d41b865d53a42a5f5"
              class="poster-img col-6"
            />
            <img
              src="https://i.ebayimg.com/images/g/ujkAAOSwYEhjyt9a/s-l1200.jpg"
              class="poster-img col-6"
            />
          </div>
        </div>
        <h1>{{ $t("owned") }}</h1>
      </div>

      <div class="card-container col-lg-4">
        <div class="card">
          <div class="card-body row">
            <img
              src="https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA2L3BkbWlzYzE2LXdrMTg2NjcxNDAtaW1hZ2VfMi5qcGc.jpg"
              class="poster-img col-6"
            />
            <img
              src="https://creativereview.imgix.net/content/uploads/2023/12/Oppenheimer.jpg"
              class="poster-img col-6"
            />
            <img
              src="https://i.etsystatic.com/18242346/r/il/4f9713/3517804383/il_570xN.3517804383_nutl.jpg"
              class="poster-img col-6"
            />
            <img
              src="https://cdn.prod.website-files.com/6009ec8cda7f305645c9d91b/66a4263d01a185d5ea22eeec_6408f6e7b5811271dc883aa8_batman-min.png"
              class="poster-img col-6"
            />
          </div>
        </div>
        <h1>{{ $t("wishlist") }}</h1>
      </div>

      <div class="card-container col-lg-4">
        <div class="card">
          <div class="card-body row">
            <img
              src="https://m.media-amazon.com/images/I/81QepEegyKL._AC_UF894,1000_QL80_.jpg"
              class="poster-img col-6"
            />
            <img
              src="https://images.photowall.com/products/59754/alien.jpg?h=699&q=85"
              class="poster-img col-6"
            />
            <img
              src="https://image.tmdb.org/t/p/original/HzrPn1gEHWixfMOvOehOTlHROo.jpg"
              class="poster-img col-6"
            />
            <img
              src="https://image.tmdb.org/t/p/original/6ehsVHJGeuhPZIN30f2Bi99xBpj.jpg"
              class="poster-img col-6"
            />
          </div>
        </div>
        <h1>{{ $t("franchises") }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
